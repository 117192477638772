<template>
    <div>
        <b-row class="d-flex justify-content-between">
            <b-col cols="12" class="d-flex align-items-center">
                <h2 class="m-0">
                    <slot name="title"> Add game items </slot>
                </h2>
            </b-col>
        </b-row>

        <b-row class="d-flex justify-content-center">
            <b-col cols="12" xl="4">
                <b-card class="mt-2">
                    <validation-observer
                        ref="addGameItem"
                        #default="{ invalid }"
                        slim
                    >
                        <b-form @submit.prevent>
                            <!-- Game item name -->
                            <b-form-group
                                id="game-item-name-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.name')"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('gameItem.name')"
                                    :rules="`required|max:${gameItemNameOptions.maxlength}`"
                                >
                                    <b-form-input
                                        id="game-item-name-input"
                                        v-model.trim="gameItemName"
                                        required
                                        :maxlength="
                                            gameItemNameOptions.maxlength
                                        "
                                        :name="$t('gameItem.name')"
                                        width="100"
                                    />

                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </b-form-group>

                            <!-- Game Item Inner name -->
                            <b-form-group
                                v-if="showInnerName"
                                id="game-item-inner-name-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.innerName')"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('gameItem.innerName')"
                                    :rules="`required|max:${gameItemNameOptions.maxlength}`"
                                >
                                    <b-form-input
                                        id="game-item-name-input"
                                        v-model.trim="gameItemInnerName"
                                        required
                                        :maxlength="
                                            gameItemNameOptions.maxlength
                                        "
                                        :name="$t('gameItem.innerName')"
                                        width="100"
                                    />

                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </b-form-group>

                            <!-- Weapon select -->
                            <b-form-group
                                v-if="showWeaponSelector"
                                id="weapon-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.weapon')"
                                class="mt-2"
                            >
                                <validation-provider
                                    :name="$t('gameItem.weapon')"
                                    rules="required"
                                    slim
                                >
                                    <weapons-select-single v-model="weaponID" />
                                </validation-provider>
                            </b-form-group>

                            <!-- Rarity select -->
                            <b-form-group
                                id="rarity-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.rarity')"
                                class="mt-2"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    :name="$t('gameItem.rarity')"
                                    rules="required"
                                >
                                    <v-select
                                        v-model="rarityID"
                                        :dir="
                                            $store.state.appConfig.isRTL
                                                ? 'rtl'
                                                : 'ltr'
                                        "
                                        :options="filteredRarities"
                                        class="select w-100"
                                        label="name"
                                        :reduce="(rarity) => rarity.id"
                                        required
                                        width="100"
                                    >
                                        <template v-slot:option="option">
                                            <span
                                                class="select__circle"
                                                :style="`background-color: ${option.main_color}`"
                                            />
                                            {{ option.name }}
                                        </template>
                                    </v-select>

                                    <small class="text-danger">
                                        {{ errors[0] }}
                                    </small>
                                </validation-provider>
                            </b-form-group>

                            <!-- Legal switch -->
                            <b-form-group
                                id="is-legal-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.isLegal')"
                                class="mt-2"
                            >
                                <label
                                    class="mr-50 mb-0"
                                    @click="isLegal = !isLegal"
                                >
                                    {{ $t('ui.no') }}
                                </label>

                                <b-form-checkbox
                                    v-model="isLegal"
                                    if="is-legal-switch"
                                    name="check-button"
                                    switch
                                    inline
                                >
                                    {{ $t('ui.yes') }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- Custom switch -->
                            <b-form-group
                                v-if="showCustomSwitch"
                                id="is-custom-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.isCustom')"
                                class="mt-2"
                            >
                                <label
                                    class="mr-50 mb-0"
                                    @click="isCustom = !isCustom"
                                >
                                    {{ $t('ui.no') }}
                                </label>

                                <b-form-checkbox
                                    v-model="isCustom"
                                    name="check-button"
                                    switch
                                    inline
                                >
                                    {{ $t('ui.yes') }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- Public switch -->
                            <b-form-group
                                v-if="isCustom"
                                id="is-public-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.isPublic')"
                                class="mt-2"
                            >
                                <label
                                    class="mr-50 mb-0"
                                    @click="isPublic = !isPublic"
                                >
                                    {{ $t('ui.no') }}
                                </label>

                                <b-form-checkbox
                                    v-model="isPublic"
                                    name="check-button"
                                    switch
                                    inline
                                >
                                    {{ $t('ui.yes') }}
                                </b-form-checkbox>
                            </b-form-group>

                            <!-- Image URL -->
                            <b-form-group
                                v-if="showImageUrl"
                                id="image-url-fieldset"
                                label-class="font-weight-bold"
                                :label="$t('gameItem.imageURL')"
                                class="mt-2 mb-0"
                            >
                                <b-form-input
                                    id="game-item-image-url-input"
                                    v-model="imageURL"
                                    :name="$t('gameItem.imageURL')"
                                    width="100"
                                />
                            </b-form-group>

                            <!-- Submit button -->
                            <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                class="mt-2"
                                variant="primary"
                                type="submit"
                                :disabled="invalid || isLoading"
                                @click="addNewGameItem"
                            >
                                <span
                                    v-if="isLoading"
                                    class="d-flex align-items-end"
                                >
                                    <b-spinner
                                        variant="light"
                                        small
                                        class="mr-50"
                                    />

                                    <span>
                                        {{ $t('ui.loading') }}
                                    </span>
                                </span>

                                <span v-else>
                                    {{ $t('ui.add') }}
                                </span>
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max } from '@validations';

import {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BSpinner,
    VBModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';

import WeaponsSelectSingle from '@/views/weapons/weapons-select/WeaponsSelectSingle.vue';
import useWeapons from '@/views/weapons/useWeapons';
import usePositionedToast from '@/utils/usePositionedToast';
import useHandleError from '@/utils/useHandleError';
import useGameItems from '../useGameItems';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BCard,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BButton,
        BSpinner,

        vSelect,

        WeaponsSelectSingle,
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },
    },

    data() {
        return {
            isLoading: false,

            // validation rules
            required,
            max,
        };
    },

    computed: {
        rarities() {
            return this.$store.getters['app-rarities/GET_RARITIES'] || [];
        },

        filteredRarities() {
            if (this.rarityDependsOnCustom) {
                return this.rarities.filter(
                    (rarity) => rarity.is_custom === this.isCustom
                );
            }

            return this.rarities.filter((rarity) => rarity.is_custom);
        },
    },

    watch: {
        filteredRarities() {
            this.rarityID = null;
        },
    },

    methods: {
        collectGameItemData() {
            const gameItemData = {
                is_custom: this.isCustom,
                is_legal: this.isLegal,
                name: this.gameItemName,
                weapon_id: this.weaponID,
                rarity_id: this.rarityID,
            };

            if (this.showInnerName)
                gameItemData.inner_name = this.gameItemInnerName;

            if (this.isCustom) gameItemData.is_public = this.isPublic;

            if (this.imageURL) gameItemData.image_url = this.imageURL;

            if (
                this.showWeaponSelector &&
                (this.weaponID === null || this.weaponID === undefined)
            ) {
                this.showNotification({
                    title: this.$t('gameItem.weaponNotSelected'),
                    variant: 'danger',
                });

                return Promise.reject(new Error('Validation error'));
            }

            return Promise.resolve(gameItemData);
        },

        addNewGameItem() {
            this.isLoading = true;

            this.$refs.addGameItem
                .validate()
                .then((success) => {
                    if (success) {
                        return this.collectGameItemData();
                    }

                    return Promise.reject(new Error('Validation error'));
                })
                .then((gameItemData) => {
                    return this.postNewGameItem({
                        gameItemData,
                        locale: this.$i18n.locale,
                    });
                })
                .then((response) => {
                    this.showNotification({
                        title: response.data.message,
                        variant: 'success',
                    });

                    this.$emit('added', response.data);
                })
                .catch(this.showErrorMessage)
                .finally(() => {
                    this.isLoading = false;
                });
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const { showNotification } = usePositionedToast();
        const { showErrorMessage } = useHandleError();
        const { getWeaponByID } = useWeapons();
        const {
            showInnerName,
            showCustomSwitch,
            showWeaponSelector,
            showImageUrl,
            rarityDependsOnCustom,
            gameItemNameOptions,
            gameItemName,
            gameItemInnerName,
            weaponID,
            isLegal,
            isCustom,
            isPublic,
            imageURL,
            rarityID,
            selectedWeapon,
            postNewGameItem,
        } = useGameItems({ gameItemVariant });

        return {
            // Reactive data
            showInnerName,
            showCustomSwitch,
            showWeaponSelector,
            showImageUrl,
            rarityDependsOnCustom,
            gameItemNameOptions,
            gameItemName,
            gameItemInnerName,
            weaponID,
            isLegal,
            isCustom,
            isPublic,
            imageURL,
            rarityID,
            selectedWeapon,

            // Methods
            getWeaponByID,
            postNewGameItem,
            showNotification,
            showErrorMessage,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@/utils/scss/vue-select-rarity.scss';
</style>
